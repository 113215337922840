<template>
   <div class="main">
    <div class="chapter-view content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/dashboard"><span>Dashboard</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> <router-link to="/course"><span>Course</span></router-link> </a-breadcrumb-item>
        <a-breadcrumb-item> <router-link :to="'/course/' + courseId + '/chapter'"><span>Chapter</span></router-link> </a-breadcrumb-item>
         <a-breadcrumb-item> View </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="add">
            <a-button type="primary">
              <router-link :to="{ name: 'List Chapter', params: { id: courseId }}"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <div class="chapter">
        <a-tabs v-model:activeTab="activeTab">
          <a-tab-pane key="1" tab="General">
            <ViewChapter />
          </a-tab-pane>
          <a-tab-pane key="2" tab="Quiz" force-render v-if="chapter?.is_assesment">
            <a-row v-if="!disableAddQuiz">
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="add-quiz" >
                  <a-button type="primary" @click="showAddQuizModal">
                    <PlusOutlined /> Add Quiz
                  </a-button>
                </div>
              </a-col>
            </a-row>
            <ListQuiz :refreshQuizListKey="refreshQuizListKey" :quiz="quiz" @refreshQuizList="refreshQuiz" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <AddEditQuiz :showQuizModel="showQuizModel" @refreshQuizList="refreshQuiz" />
   </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import { notification } from 'ant-design-vue'

import ViewChapter from '@/components/agency/chapter/ViewChapter.vue'
import AddEditQuiz from '@/components/agency/quiz/AddEditQuiz.vue'
import ListQuiz from '@/components/agency/quiz/ListQuiz.vue'

import chapterService from '../../../services/chapter'
import quizService from '../../../services/quiz'

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    ViewChapter,
    AddEditQuiz,
    ArrowLeftOutlined,
    ListQuiz
  },
  setup () {
    const route = useRoute()
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const quiz = ref<{ _id: string; name: string; description: string; is_published: boolean; start_time: string; end_time: string; no_of_attempt: number; review: boolean; shuffle: boolean; no_of_questions: number; time_limit: number; course: string; chapter: string }>()
    const activeTab = ref<number>(1)
    const showQuizModel = ref<number>(0)
    const chapter = ref()
    const quizzes = ref()
    const disableAddQuiz = ref<boolean>(false)
    const showAddQuizModal = () => {
      showQuizModel.value++
    }
    const refreshQuizListKey = ref<number>(0)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getCourseQuiz = async () => {
      try {
        const responce = await quizService.getCourseChapterQuiz(courseId.value, chapterId.value)
        quizzes.value = responce.data
        if (responce.data.length >= 1) {
          disableAddQuiz.value = true
        } else {
          disableAddQuiz.value = false
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const refreshQuiz = async (data) => {
      if (data) {
        quiz.value = data.data
        refreshQuizListKey.value++
      }
      getCourseQuiz()
    }
    const getChapterDetails = async () => {
      try {
        const responce = await chapterService.getChapterDetails(chapterId.value)
        chapter.value = responce.data
      } catch (error) {

      }
    }
    onMounted(() => {
      getChapterDetails()
      getCourseQuiz()
    })
    return {
      courseId,
      chapterId,
      activeTab,
      showQuizModel,
      showAddQuizModal,
      refreshQuizListKey,
      refreshQuiz,
      quiz,
      chapter,
      quizzes,
      disableAddQuiz
    }
  }
})
</script>
<style lang="scss">
.chapter-view {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .chapter {
    text-align: left;
    border-radius: 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #00000014;
    padding: 20px;
    margin: 10px;
    .ant-tabs {
      .ant-row {
        .add-quiz {
          float: right;
          padding-bottom: 15px;
          .ant-btn {
            background: #38B6FF;
            border-color: #38B6FF;
            border-radius: 15px;
          }
          .ant-btn[disabled] {
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
